import request from '@/utils/request'

export function getList() {
    return request({
        url: '/record/list', 
        method: 'get'
    })
}

export function addList(data) {
    return request({
        url: '/record/save', 
        method: 'post',
        data
    })
}

export function editList(id,data) {
    return request({
        url: '/record/edit?id=' + id, 
        method: 'post',
        data
    })
}


export function delList(id) {
    return request({
        url: '/record/delete?id=' + id, 
        method: 'post',
    })
}
