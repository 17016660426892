<template>
  <div>
    <div class="nav">
      <h1>业务管理/客户资金记录</h1>
    </div>
    <div class="search">
      <div class="left">
        <div class="box">
          <el-input
            placeholder="请输入内容"
            style="width: 300px"
            clearable
            class="input-with-select"
          >
            <template slot="prepend">姓名</template>
          </el-input>
        </div>
      </div>
      <div class="right">
        <el-button type="primary" @click="saveRecord()">新增记录</el-button>
      </div>
    </div>

    <div class="result">
      <span></span>
      <p>查询结果</p>
    </div>

    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        header-cell-style="background: #f5f7fa;"
      >
        <el-table-column
          align="center"
          prop="name"
          width="200"
          :show-overflow-tooltip="true"
          label="客户名称"
          fixed="left"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="name"
          :show-overflow-tooltip="true"
          label="交易类型"
        >
          <template slot-scope="scope">
            <el-tag type="success">{{
              scope.row.type == 1 ? "提现" :
              scope.row.type == 2 ? "充值" : "结算"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="amount"
          :show-overflow-tooltip="true"
          label="交易金额"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="createtime"
          width="300"
          label="交易时间"
        ></el-table-column>
        <!-- <el-table-column align="center" prop="sortOrder" label="排序" width="100"></el-table-column> -->
        <el-table-column align="center" label="操作" fixed="right" width="200">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                @click.native="editList(scope.row)"
                effect="dark"
                content="编辑数据"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-edit"></i>
                </p>
              </el-tooltip>

              <el-tooltip
                class="item"
                @click.native="delList(scope.row.id)"
                effect="dark"
                content="删除数据"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-delete-solid"></i>
                </p>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[10, 50, 100, 150, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <operationRecord
      :dialogVisible="dialogVisible"
      :data="con"
      @outClose="dialogVisible = false"
    />
  </div>
</template>
    
    <script>
import operationRecord from "./components/operationRecord.vue";
import { getList, delList } from "@/api/record";
export default {
  components: {
    operationRecord,
  },
  inject: ["reload"],
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      dialogVisible: false,
      amount: "",
      type: 0,
      tableData: [],
      con: {}
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dialogVisible(data) {
      if (!data) {
        this.getList();
        this.amount = "";
      }
    },
  },
  methods: {
    // 提现充值
    editRecharge(data, type) {
      this.type = type;
      this.con = JSON.parse(JSON.stringify(data));
      this.rechargeDialogVisible = true;
    },
    saveRecord() {
      this.dialogVisible = true;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getList();
    },

    editList(data) {
      this.con = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
    },
    delList(id) {
      let that = this;
      this.$confirm("您正在删除订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delList(id).then((res) => {
            console.log(res);
            that.dialogVisible = false;
            that.$message.success("删除成功");
            that.reload();
          });
        })
        .catch(() => {
          that.$message.info("删除失败");
        });
    },
    getList() {
      getList().then((res) => {
        this.tableData = res.data;
      });
    },
  },
};
</script>
    
    <style lang="less" scoped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
/deep/ .el-dialog__body {
  padding: 10px 20px;
}

.list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 13px;
  p {
    font-size: 15px;
    color: rgba(125, 133, 146, 1);
    margin-bottom: 10px;
  }
  .el-input {
    width: 100%;
  }
}

.list-dialog {
  //   margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  .list {
    width: 100%;
    .el-input {
      width: 100%;
    }
  }
}

.search {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .left {
    width: 60%;
    display: flex;
    align-items: flex-end;
    .box {
      display: flex;
      /deep/ .el-input {
        margin-right: 20px;
        //   margin-left: 20px;
      }
    }
    /deep/ .el-select {
      width: 250px;
      margin-right: 20px;
    }
    .el-button {
      margin-left: 20px;
    }
    .input-with-select {
      width: 600px;
    }
  }
  .right {
    // width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .box {
      display: flex;
      align-items: center;
    }
  }
}

.table {
  margin-top: 20px;
  .shop {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    p {
      position: relative;
    }
  }
}
</style>